import React from "react"
import styled from "styled-components"
import { H1, H3, options } from "../../components/theme"
import { HomeImage } from "../../components/theme"
import GridComponent from "../GridComponent"

const HomeContainer = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 4;
  min-height: 800px;
`
const HomeTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 160px;
  height: 100%;
  width: auto;
  z-index: 4;
  /* background: violet; */
  @media screen and (max-width: 1200px) {
    width: calc(100% - 320px);
    /* margin-left: 0; */
  }
  @media screen and (max-width: 600px) {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
`

const DownText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* width: 92%; */
  margin: 0 0 0 5px;
  h1 {
    margin: 0 230px 1.45rem 0;
  }
  @media screen and (max-width: 1200px) {
    display: none;
  }
`

const Button = styled.a`
  background: #4cbec8;
  width: 380px;
  height: 80px;
  border: 10px solid ${options.turquoise};
  border-radius: 5px;
  color: ${options.white};
  font-family: ${options.fontFamily};
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 5;
  margin: 0 0 0 5px;
  @media screen and (max-width: 800px) {
    width: 100%;
    margin: 0;
  }
`

const Container = styled.div`
  position: relative;
`

const HomeImageSection = styled.div`
  /* grid-column: 1 / end;
  grid-row: 1 / end; */
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const Headline = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* width: auto; */
  /* @media screen and (max-width: 1200px) {
    text-align: center;
  } */
`

const HeaderSection = props => {
  return (
    <Container>
      <GridComponent />
      <HomeImageSection>
        <HomeImage src={props.back} alt="" />
      </HomeImageSection>
      <HomeContainer>
        <HomeTextContainer>
          <Headline>
            <H1>{props.headline}</H1>
          </Headline>
          <DownText>
            <H3>{props.underline}</H3>
          </DownText>
          <Button href={props.href}>{props.btn}</Button>
        </HomeTextContainer>
      </HomeContainer>
    </Container>
  )
}

export default HeaderSection
