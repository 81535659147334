import React from "react"
import Back from "../images/dorcol.jpg"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderSection from "../components/sections/HeaderSection"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />

    <HeaderSection
      headline="NOT FOUND"
      underline="You just hit a route that doesn&#39;t exist... the sadness."
      href="/"
      btn="Povratak na Početnu"
      back={Back}
    />
  </Layout>
)

export default NotFoundPage
